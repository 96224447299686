import React, { useState, useEffect } from 'react';
import {
    TextField,
    Grid,
    Typography,
    Card,
    CardContent,
    CardMedia,
    Container,
    Button,
    CircularProgress,
    Collapse,
} from '@mui/material';
import axios from 'axios';

function ProductList() {
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [visibleCount, setVisibleCount] = useState(6); // State to track visible products
    const [expandedIndex, setExpandedIndex] = useState(null); // Track which product is expanded

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true); // Set loading to true before fetching
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/operational-admin/products`);
                setProducts(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchProducts();
    }, []);

    // Filter products based on the search term
    const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleViewMore = () => {
        setVisibleCount((prevCount) => prevCount + 6); // Increase the number of visible products
    };

    const handleReadMoreToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); // Toggle expanded index
    };

    // Button styles similar to Home component
    const buttonStyles = {
        fontFamily: 'Poppins, sans-serif', // Changed to Poppins
        fontWeight: 600,
        fontSize: '1rem',
        color: '#FFF',
        backgroundColor: '#DAB060',
        border: 0,
        padding: '8px 16px',
        borderRadius: '20px',
        position: 'relative',
        transition: 'transform 0.1s',
        '&:hover': {
            transform: 'translateY(-10%) scale(1.1)',
            boxShadow: '0 0 10px rgba(218, 176, 96, 0.5)',
        },
        '&:active': {
            transform: 'translateY(5%) scale(0.9)',
        },
    };

    return (
        <Container className="mt-5">
            <Typography variant="h4" className="mb-4" align="center" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                Product List
            </Typography>
            <TextField
                variant="outlined"
                placeholder="Search for a product..."
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-4"
                sx={{ borderRadius: '20px', fontFamily: 'Poppins, sans-serif' }} // Changed to Poppins
            />

            {loading ? (
                <Container align="center">
                    <CircularProgress />
                </Container>
            ) : error ? (
                <Typography variant="h6" align="center" className="mt-4" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                    Error: {error}
                </Typography>
            ) : (
                <>
                    <Grid container spacing={3}>
                        {filteredProducts.slice(0, visibleCount).map((product, index) => (
                            <Grid item xs={12} sm={6} md={4} key={product._id}>
                                <Card
                                    className="shadow-lg"
                                    sx={{ borderRadius: '20px', overflow: 'hidden', transition: 'height 0.3s ease', height: expandedIndex === index ? 'auto' : '400px' }} // Dynamic height
                                >
                                    <CardMedia
                                        component="img"
                                        height="200" // Fixed height for images
                                        image={product.images[0] || 'https://via.placeholder.com/200'} // Fallback image if not available
                                        alt={product.name}
                                        sx={{ borderRadius: '20px 20px 0 0', objectFit: 'contain' }} // Rounded corners and contain
                                    />
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                        <Typography variant="h5" component="div" sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600, flexShrink: 0 }}>
                                            {product.name}
                                        </Typography>
                                        
                                        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Poppins, sans-serif', flexGrow: 1 }}>
                                            {product.description.split(' ').slice(0, 6).join(' ') + (expandedIndex === index ? ' ' : '...')} {/* Show first six words */}
                                        </Typography>
                                        
                                        <Button 
                                            size="small" 
                                            onClick={() => handleReadMoreToggle(index)} 
                                            sx={{ mt: 1, fontFamily: 'Poppins, sans-serif', alignSelf: 'flex-start' }}
                                        >
                                            {expandedIndex === index ? 'Read Less' : 'Read More'}
                                        </Button>
                                        
                                        {expandedIndex === index && (
                                            <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Poppins, sans-serif', marginTop: 1 }}>
                                                {product.description}
                                            </Typography>
                                        )}
                                    </CardContent>
                                    <Typography variant="h6" sx={{ fontFamily: 'Poppins, sans-serif', mt: 2, mb: 1, textAlign: 'right', padding: '0 16px' }}>
                                        ₹ {product.price}
                                    </Typography>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {filteredProducts.length === 0 && (
                        <Typography variant="h6" align="center" className="mt-4" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                            No products found.
                        </Typography>
                    )}
                    {visibleCount < filteredProducts.length && (
                        <div className="text-center mt-4">
                            <Button
                                variant="contained"
                                sx={buttonStyles} // Apply the same button styles here
                                onClick={handleViewMore}
                            >
                                View More
                            </Button>
                        </div>
                    )}
                </>
            )}
        </Container>
    );
}

export default ProductList;