import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Button,
  Typography,
  TextField,
  Container,
  Grid,
  Box,
} from "@mui/material"; // Grouped MUI components
import { Link } from "react-router-dom"; // Import Link from react-router-dom

// Grouping Material-UI Icons
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PinDropIcon from "@mui/icons-material/PinDrop";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Importing images
import Hero from "../Assets/BackGroundImg/Home.webp";
import farmer from "../Assets/images/farmer.png";
import Logo from "../Assets/images/Kisaanstarlogo1.webp";
import Services1 from "../Assets/images/AgriConsultancy.webp";
import Services2 from "../Assets/images/HomeDelivery_1.webp";
import Services3 from "../Assets/images/PremiumProducts_1.webp";
import Service4 from "../Assets/images/SoilTesting_1.webp";
import Service5 from "../Assets/images/AVTraningProgram_1.webp";
import webbanner from "../Assets/images/web banner.webp";

import Advisor from "../Assets/images/Advisor.webp";
import Advisor1 from "../Assets/images/Advisor1.webp";

import AgricultureIcon from "@mui/icons-material/Agriculture"; // Add this
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory"; // Add this
import ScienceIcon from "@mui/icons-material/Science";
import SchoolIcon from "@mui/icons-material/School";

// Other imports
import "bootstrap/dist/css/bootstrap.min.css";
import HoverCarousel from "hover-carousel";
import ProductList from "./Productlist";
import Gallery from "../components/SmallComponents/Gallery";
import ContactForm from "./ContactForm";

function Home() {
  const images = [Services1, Services2, Services3, Service4, Services1];

  const [scrolled, setScrolled] = useState(false);

  // Counter Component with Animation
  const Counter = ({ title, targetNumber, percentage, color }) => {
    const [currentNumber, setCurrentNumber] = useState(0);

    useEffect(() => {
      const duration = 2000; // Animation duration in milliseconds
      const incrementTime = 50; // Time interval for each increment in milliseconds
      const totalSteps = duration / incrementTime; // Total number of steps
      const incrementValue = Math.ceil(targetNumber / totalSteps); // Value to increment at each step

      let count = 0;
      const interval = setInterval(() => {
        count += incrementValue;
        if (count >= targetNumber) {
          clearInterval(interval);
          count = targetNumber; // Ensure it ends on targetNumber
        }
        setCurrentNumber(count);
      }, incrementTime);

      return () => clearInterval(interval); // Cleanup on unmount
    }, [targetNumber]);

    const circleSize = 150; // Size of the circle
    const strokeWidth = 15; // Width of the circle's stroke
    const radius = (circleSize - strokeWidth) / 2; // Radius of the circle
    const circumference = 2 * Math.PI * radius; // Circumference of the circle
    const offset = circumference - (percentage / 100) * circumference; // Offset for stroke-dasharray

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="col"
      >
        <Box position="relative" display="inline-block">
          <svg width={circleSize} height={circleSize}>
            <circle
              stroke="#dadada"
              fill="transparent"
              strokeWidth={strokeWidth}
              r={radius}
              cx={circleSize / 2}
              cy={circleSize / 2}
            />
            <circle
              stroke={color}
              fill="transparent"
              strokeWidth={strokeWidth}
              r={radius}
              cx={circleSize / 2}
              cy={circleSize / 2}
              strokeDasharray={`${circumference} ${circumference}`}
              strokeDashoffset={offset}
              style={{ transition: "stroke-dashoffset 0.5s ease-in-out" }}
            />
          </svg>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ color: "#000", fontFamily: "Poppins, sans-serif" }}
            >
              {currentNumber}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="h6"
          style={{ marginTop: "10px", fontFamily: "Poppins, sans-serif" }}
        >
          {title}
        </Typography>
        <Typography style={{ color, fontFamily: "Poppins, sans-serif" }}>
          {percentage}%
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Button styles
  const buttonStyles = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#FFF",
    backgroundColor: "#DAB060",
    border: 0,
    padding: "8px 16px",
    position: "relative",
    transition: "transform 0.1s",
    "&:hover": {
      transform: "translateY(-10%) scale(1.1)",
      boxShadow: "0 0 10px rgba(218, 176, 96, 0.5)",
    },
    "&:active": {
      transform: "translateY(5%) scale(0.9)",
    },
  };

  const phoneNumber = "+91 8830385928";
  const emailAddress = "info@kisaanstar.com";
  const address =
    "4th floor, office number 401, Vishwaraj Pride, Nagar Rd, near hp petrol pump, Wagholi, Pune, Maharashtra 412207";

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {/* Header Section */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: scrolled ? "#445038" : "transparent",
          boxShadow: "none",
          zIndex: 1201,
          transition: "background-color 0.3s ease",
        }}
      >
        <Toolbar
          className="d-flex justify-content-between align-items-center"
          sx={{ maxWidth: "100%", padding: "0 20px" }}
        >
          {/* Logo */}
          <img
            src={Logo}
            alt="Logo"
            style={{
              height: "60px",
              borderRadius: "20px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />

          {/* Navigation Menu */}
          <nav className="d-none d-md-block">
            <ul className="list-unstyled d-flex mb-0">
              {["Home", "About", "Services", "Contact"].map((item, index) => (
                <li className="mx-3" key={index}>
                  <Button
                    component="a"
                    href={`#${item.toLowerCase()}`} // Link to the section ID with the hash
                    smooth={true} // Smooth scrolling (not applicable for plain anchor, need CSS/JS)
                    duration={500}
                    color="inherit"
                    sx={{
                      color: scrolled ? "#fff" : "#000",
                      fontFamily: "Poppins, sans-serif",
                      "&:hover": {
                        border: "2px solid #DAB060",
                        borderRadius: "5px",
                        boxShadow: "0 0 10px rgba(218, 176, 96, 0.5)",
                      },
                    }}
                  >
                    {item}
                  </Button>
                </li>
              ))}
              {/* Add the CRM link using Link */}
              <li className="mx-3">
                <Button
                  component={Link} // Using Link
                  to="/login" // Link to the login page
                  color="inherit"
                  sx={{
                    color: scrolled ? "#fff" : "#000",
                    fontFamily: "Poppins, sans-serif",
                    "&:hover": {
                      border: "2px solid #DAB060",
                      borderRadius: "5px",
                      boxShadow: "0 0 10px rgba(218, 176, 96, 0.5)",
                    },
                  }}
                >
                  CRM
                </Button>
              </li>
            </ul>
          </nav>
          {/* Search Bar and Cart Icon */}
          <div className="d-flex align-items-center">
            <div className="position-relative" style={{ width: "auto" }}>
              <InputBase
                placeholder="Search..."
                sx={{
                  border: "1px solid #FFF",
                  borderRadius: "4px",
                  padding: "2px 10px",
                  backgroundColor: "#fff",
                  minWidth: "150px",
                  maxWidth: "250px",
                  fontFamily: "Poppins, sans-serif", // Use Poppins font for search input
                }}
              />
              <IconButton
                type="submit"
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  backgroundColor: "#DAB060",
                  "&:hover": {
                    backgroundColor: "#c59b03",
                  },
                }}
              >
                <SearchIcon sx={{ color: "#445038" }} />
              </IconButton>
            </div>

            {/* Shopping Cart Icon */}
            <IconButton
              edge="end"
              color="inherit"
              sx={{ marginLeft: "10px", color: "#FFF" }}
            >
              <ShoppingCartIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {/* Hero Image Section with Overlay */}
      <div className="position-relative" style={{ overflow: "hidden" }}>
        <img
          src={Hero}
          alt="Hero Background"
          className="img-fluid w-100"
          style={{ height: "auto", maxHeight: "700px", objectFit: "cover" }}
        />
        <div
          className="position-absolute top-50 start-0 translate-middle-y text-white p-4"
          style={{ zIndex: 1 }}
        >
        </div>
      </div>

      <div id="about" style={{ width: "100%", overflow: "hidden" }}>
        {/* 50-50 Grid Layout */}
        <div className="row no-gutters" style={{ width: "100%", margin: 0 }}>
          {/* Text Content Section */}
          <div className="col-12 col-md-6 col-lg-8 d-flex align-items-center justify-content-center p-3">
            <Box textAlign="left">
              <h1
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "black",
                  fontSize: "2.5rem",
                }}
              >
                Cultivating Success, Harvesting Growth
              </h1>
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: "black",
                  fontSize: "1rem",
                }}
              >
                "KisaanStar is an innovative e-commerce platform dedicated to
                empowering farmers by providing them with direct access to
                high-quality agricultural products. Our mission is to enhance
                the agricultural ecosystem by delivering 100% quality
                agri-inputs right to the doorstep of farmers. With a focus on
                sustainable practices and cutting-edge technology, KisaanStar
                strives to elevate agriculture to new heights, supporting
                farmers in their growth and productivity. Through our reliable
                services, we aim to revolutionize the agricultural industry and
                contribute to its growth at every level."
              </p>
              <div>
                <Button
                  variant="contained"
                  sx={buttonStyles}
                  style={{ marginRight: "10px" }}
                >
                  Learn More
                </Button>
                <Button
                  variant="contained"
                  sx={buttonStyles}
                  component="a"
                  href="#contact"
                >
                  Connect Now!
                </Button>
              </div>
            </Box>
          </div>

          {/* Image Section */}
          <div className="col-12 col-md-6 col-lg-4 d-flex p-0 justify-content-end">
            <img
              src={farmer}
              alt="Side image of vegetables"
              className="img-fluid unique-img-style" // Updated class name to a unique one
            />
          </div>
        </div>

        <style>
          {`
            .unique-img-style {
              width: 100%;
              max-width: 570px; // Ensuring it doesn't exceed 570px
              height: auto; // Maintains aspect ratio
              max-height: 500px; // Ensures it doesn't exceed 500px
            }

            @media (max-width: 1024px) {
              .unique-img-style {
                width: 483px;
                height: 500px;
              }
            }
            @media (min-width: 1025px) {
              .unique-img-style {
                width: 570px;
                height: 500px;
              }
            }
          `}
        </style>
      </div>

      {/* Services Section */}
      <Container id="services" maxWidth="xl" className="text-center my-5">
        <Box sx={{ backgroundColor: "#fff", py: 5 }}>
          <Typography
            variant="h6"
            sx={{
              color: "#DAB060",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Our Services
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: "#445038",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
              mb: 3,
            }}
          >
            What Do We Do?
          </Typography>

          <Grid container spacing={4} sx={{ mt: 4 }}>
            {[
              {
                icon: (
                  <AgricultureIcon sx={{ fontSize: 100, color: "#445038" }} />
                ),
                title: "Agri Consultancy",
                description:
                  "We offer expert guidance and personalized recommendations to farmers, helping them optimize their farming practices and achieve better yields.",
                image: Services1,
              },
              {
                icon: (
                  <LocalShippingIcon sx={{ fontSize: 100, color: "#445038" }} />
                ),
                title: "Home Delivery",
                description:
                  "KisaanStar ensures hassle-free delivery of high-quality agricultural products directly to farmer's doorsteps for convenience and timely access.",
                image: Services2,
              },
              {
                icon: (
                  <InventoryIcon sx={{ fontSize: 100, color: "#445038" }} />
                ),
                title: "Premium Products",
                description:
                  "We provide farmers with 100% authentic and top-quality agri-inputs that support sustainable farming and improve productivity.",
                image: Services3,
              },
            ].map((service, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  sx={{
                    position: "relative",
                    backgroundColor: "#ECEEEB",
                    borderRadius: "15px",
                    overflow: "hidden",
                    height: "400px",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                      "& .overlay": {
                        opacity: 0.7,
                      },
                      "& .title, & .description": {
                        color: "white",
                      },
                      "& svg": {
                        color: "white",
                      },
                    },
                  }}
                >
                  {/* Overlay */}
                  <Box
                    className="overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(50, 50, 50, 0.7)",
                      opacity: 0,
                      transition: "opacity 0.3s ease-in-out",
                      zIndex: 1,
                    }}
                  />

                  {/* Image Display */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "250px",
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    <img
                      src={service.image}
                      alt={service.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      p: 3,
                      position: "relative",
                      zIndex: 3,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "150px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      className="title"
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "bold",
                        color: "#445038",
                        mb: 2,
                      }}
                    >
                      {service.title}
                    </Typography>
                    <Typography
                      className="description"
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        color: "#666",
                      }}
                    >
                      {service.description}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}

            {/* Last two services in a centered grid item */}
            <Grid item xs={12} container justifyContent="center" spacing={4}>
              {[
                {
                  icon: (
                    <ScienceIcon sx={{ fontSize: 100, color: "#445038" }} />
                  ),
                  title: "Soil Testing",
                  description:
                    "Our soil testing service helps farmers understand soil health and make informed decisions for better crop growth and nutrient management.",
                  image: Service4,
                },
                {
                  icon: <SchoolIcon sx={{ fontSize: 100, color: "#445038" }} />,
                  title: "AgriVision Training Program",
                  description:
                    "We offer comprehensive agricultural training programs designed to equip farmers with valuable skills, providing 100% placement assistance for career growth in agriculture.",
                  image: Service5,
                },
              ].map((service, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: "#ECEEEB",
                      borderRadius: "15px",
                      overflow: "hidden",
                      height: "400px",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "translateY(-10px)",
                        boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                        "& .overlay": {
                          opacity: 0.7,
                        },
                        "& .title, & .description": {
                          color: "white",
                        },
                        "& svg": {
                          color: "white",
                        },
                      },
                    }}
                  >
                    {/* Overlay */}
                    <Box
                      className="overlay"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(50, 50, 50, 0.7)",
                        opacity: 0,
                        transition: "opacity 0.3s ease-in-out",
                        zIndex: 1,
                      }}
                    />

                    {/* Image Display */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "250px",
                        position: "relative",
                        zIndex: 2,
                      }}
                    >
                      <img
                        src={service.image}
                        alt={service.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        p: 3,
                        position: "relative",
                        zIndex: 3,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "150px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        className="title"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "bold",
                          color: "#445038",
                          mb: 2,
                        }}
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        className="description"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          color: "#666",
                        }}
                      >
                        {service.description}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Hover Carousel */}
      <div>
        <img
          src={webbanner}
          alt="Side image of vegetables"
          className="img-fluid unique-banner-style" // Updated class name for uniqueness
        />
      </div>

      <style>
        {`
          .unique-banner-style {
            width: 100%;       // Default width
            height: 500px;     // Default height
          }

          @media (max-width: 1024px) and (min-width: 768px) {
            .unique-banner-style {
              height: 512px;   // Height for devices between 768px and 1024px
              width: 100%;      // Width remains 100%
            }
          }

          @media (min-width: 1025px) {
            .unique-banner-style {
              height: 550px;   // Height for devices above 1024px
              width: 100%;      // Width remains 100%
            }
          }
        `}
      </style>

      {/* Team Section */}
      {/* <Container className="text-center my-5">
        ...
      </Container> */}

      {/* Products below */}
      <ProductList />

      {/* <div className="mt-4">
        <Gallery />
      </div> */}

      {/* Contact Us Section */}
      <Container id="contact" className="my-5">
        <div className="row">
          <div className="col-md-6">
            <ContactForm />
          </div>

          <div className="col-md-6">
            <Typography variant="h4" style={{ fontFamily: "Poppins, sans-serif" }}>
              Company Information
            </Typography>
            <div className="d-flex align-items-center mb-2">
              <PhoneInTalkIcon />
              <Typography
                variant="body1"
                sx={{ ml: 2, cursor: "pointer" }}
                style={{ fontFamily: "Poppins, sans-serif" }}
                component="a"
                href={`tel:${phoneNumber}`}
              >
                {phoneNumber}
              </Typography>
            </div>
            <div className="d-flex align-items-center mb-2">
              <MailOutlineIcon />
              <Typography
                variant="body1"
                sx={{ ml: 2 }}
                style={{ fontFamily: "Poppins, sans-serif" }}
                component="a"
                href={`mailto:${emailAddress}`}
              >
                {emailAddress}
              </Typography>
            </div>
            <div className="d-flex align-items-center mb-4">
              <PinDropIcon />
              <Typography
                variant="body1"
                sx={{ ml: 2, cursor: "pointer" }}
                style={{ fontFamily: "Poppins, sans-serif" }}
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      address
                    )}`,
                    "_blank"
                  )
                }
              >
                Address: {address}
              </Typography>
            </div>
            <Typography variant="h4" style={{ fontFamily: "Poppins, sans-serif" }}>
              Follow Us
            </Typography>
            <div className="d-flex justify-content-start">
              <IconButton
                aria-label="instagram"
                sx={{ color: "#C13584", margin: "0 5px", fontSize: "2rem" }}
                component="a"
                href="https://www.instagram.com/kisaanstar?igsh=YWs3d2V3MW5oejE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="youtube"
                sx={{ color: "#FF0000", margin: "0 5px", fontSize: "2rem" }}
                component="a"
                href="https://youtube.com/@kisaanstar?si=21s_5XXHiH7HwGdO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="linkedin"
                sx={{ color: "#0077B5", margin: "0 5px", fontSize: "2rem" }}
                component="a"
                href="https://www.linkedin.com/company/kisaanstar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        </div>
      </Container>

      {/* Footer Section */}
      <footer
        className="bg-white text-center py-4"
        style={{ backgroundColor: "#445038" }}
      >
        <Typography
          variant="body1"
          style={{ color: "#445038", fontFamily: "Poppins, sans-serif" }}
        >
          © {new Date().getFullYear()} Kisaanstar PvtLtd - All Rights Reserved
        </Typography>
        <div className="d-flex justify-content-center">
          <Button sx={{ ...buttonStyles, color: "#FFF" }}>
            Privacy Policy
          </Button>
          <Button sx={{ ...buttonStyles, color: "#FFF", marginLeft: "20px" }}>
            Terms of Use
          </Button>
        </div>
      </footer>
    </>
  );
}

export default Home;