import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Snackbar, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate input
    if (!name || !email || !message) {
      setError('All fields are required.');
      return;
    }

    // Reset errors and send email
    setSuccess('');
    setError('');

    window.Email.send({
      Host: "smtp.elasticemail.com",
      Port: 587,
      Username: "rockw964@gmail.com",
      Password: "14F8FCCE671C5ED8B4DE40AF0C45BD041E1D",
      SecureToken: "5dbbe506-5259-45f0-bfc3-19eb1a2d65a9",
      To: "kdolas99@gmail.com",
      From: "rockw964@gmail.com",
      Subject: "New Contact Form Submission from " + name,
      Body: "Name: " + name + "<br>Email: " + email + "<br>Message: " + message,
    })
    .then((response) => {
      if (response.toLowerCase() === 'ok') {
        setSuccess('Message sent successfully!');
        resetForm();
      } else {
        setError(`Failed to send message: ${response}`);
      }
    })
    .catch((err) => {
      setError('Error sending message: ' + err.message || err);
    });
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <Container maxWidth="sm" className="mt-5">
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <TextField
            fullWidth
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className="text-center">
          <Button variant="contained" color="primary" type="submit">
            Send Message
          </Button>
        </div>
      </form>
      {success && (
        <Snackbar
          open={Boolean(success)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={() => setSuccess('')}
          message={success}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSuccess('')}>
              <Close fontSize="small" />
            </IconButton>
          }
        />
      )}
      {error && (
        <Snackbar
          open={Boolean(error)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setError('')}>
              <Close fontSize="small" />
            </IconButton>
          }
        />
      )}
    </Container>
  );
};

export default ContactForm;